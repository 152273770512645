import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const MarketingPlan = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Marketing plan feature | GlassHive"
                description="Plan out your content and stay ahead with GlassHive’s easy to use marketing plan feature. Check it out!"
            />
            <div className="marketing-plan-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">
                                Automate your marketing plan
                            </h1>
                            <p className="hero-subtext white-text">
                                Easily build out your marketing plan and stay
                                consistent with GlassHive
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <WistiaPopoverVideo id={'0oyvmndjx1'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../../images/features/marketing-plan/thumbnail.png')}
                                    alt="Automated marketing plan video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">Be proactive</h4>
                            <p>
                                Content marketing is important for businesses.
                                When it comes to planning things out, it can be
                                difficult to keep track of when to send out
                                content.
                            </p>
                            <p>
                                With GlassHive, you can plan ahead and easily
                                see what content is going out with an easy to
                                see calendar view.
                            </p>
                            <p>
                                Write out all your copy ahead of time and you
                                won’t have to worry about manually sending it
                                out.
                            </p>
                            <p>
                                Check out the ways GlassHive’s marketing plan
                                feature can help your content marketing stay on
                                track by watching the video.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">Plan ahead</h4>
                            <p>
                                Schedule your content in advance with various
                                campaigns.
                            </p>
                            <h4 className="centered-mobile">
                                Automatic sending
                            </h4>
                            <p>
                                Plan ahead and know the content will be
                                automatically sent out without having to
                                manually send it.
                            </p>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Sign up for a free subscription of GlassHive" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default MarketingPlan;
